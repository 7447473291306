import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import anime from 'animejs';
import styled from 'styled-components';

const StyledLoader = styled.div`
  ${({ theme }) => theme.mixins.flexCenter};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: var(--dark-navy);
  z-index: 99;

  .logo-wrapper {
    width: max-content;
    max-width: 100px;
    transition: var(--transition);
    opacity: ${props => (props.isMounted ? 1 : 0)};
    svg {
      display: block;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      fill: none;
      user-select: none;
      #B {
        opacity: 0;
      }
    }
  }
  .loader3 {
    position: relative;
    width: 300px;
    height: 50px;

    top: 45%;
    top: -webkit-calc(50% - 20px);
    top: calc(50% - 20px);
    left: 25%;
    left: -webkit-calc(50% - 150px);
    left: calc(50% - 150px);
  }

  .loader3:after {
    content: 'LOADING ...';
    color: var(--green);
    font-family: var(--font-mono);
    font-weight: 200;
    font-size: 30px;
    position: absolute;
    width: 100%;
    height: 40px;
    line-height: 40px;
    left: 0;
    top: 0;
    background-color: var(--dark-navy);
    z-index: 1;
  }

  .loader3:before {
    content: '';
    position: absolute;
    background-color: #fff;
    top: -10px;
    left: 0px;
    height: 60px;
    width: 0px;
    z-index: 0;
    opacity: 1;
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    -webkit-animation: loader3 3s ease-in-out infinite;
    animation: loader3 3s ease-in-out infinite;
  }

  @-webkit-keyframes loader3 {
    0% {
      width: 0px;
    }
    70% {
      width: 100%;
      opacity: 1;
    }
    90% {
      opacity: 0;
      width: 100%;
    }
    100% {
      opacity: 0;
      width: 0px;
    }
  }

  @keyframes loader3 {
    0% {
      width: 0px;
    }
    70% {
      width: 100%;
      opacity: 1;
    }
    90% {
      opacity: 0;
      width: 100%;
    }
    100% {
      opacity: 0;
      width: 0px;
    }
  }
`;

const Loader = ({ finishLoading }) => {
  const [isMounted, setIsMounted] = useState(false);

  const animate = () => {
    const loader = anime.timeline({
      complete: () => finishLoading(),
    });

    loader
      .add({
        targets: '#logo path',
        delay: 300,
        duration: 1500,
        easing: 'easeInOutQuart',
        strokeDashoffset: [anime.setDashoffset, 0],
      })
      .add({
        targets: '#logo #B',
        duration: 700,
        easing: 'easeInOutQuart',
        opacity: 1,
      })
      .add({
        targets: '#logo',
        delay: 500,
        duration: 300,
        easing: 'easeInOutQuart',
        opacity: 0,
        scale: 0.1,
      })
      .add({
        targets: '.loader',
        duration: 200,
        easing: 'easeInOutQuart',
        opacity: 0,
        zIndex: -1,
      });
  };

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 10);
    animate();
    return () => clearTimeout(timeout);
  }, []);

  return (
    <StyledLoader className="loader" isMounted={isMounted}>
      <Helmet bodyAttributes={{ class: `hidden` }} />

      <div className="logo-wrapper">
        <div className="loader3"></div>
      </div>
    </StyledLoader>
  );
};

Loader.propTypes = {
  finishLoading: PropTypes.func.isRequired,
};

export default Loader;
