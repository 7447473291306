import React from 'react';
import './logo.css';

const IconLogo = () => (
  <svg
    id="logo"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    width="100px"
    height="100px"
    viewBox="-3 -4 39 39">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <polygon id="Shape" stroke="#333333" strokeWidth="1" points="16,0 32,32 0,32" />
      </g>
    </g>
  </svg>
);

export default IconLogo;
